* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  scroll-behavior: smooth;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

html {
  overscroll-behavior-y: contain;
}

#root {
  display: flex;
  flex-direction: column;
}

.page-container {
  background-color: #fff;

  &::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #6464644d;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar, *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #6464644d;
    box-shadow: inset 0 0 6px #6464644d;
    background-color: #555;
  }
}

.scroll-bar {
  &::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #6464644d;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar, *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #6464644d;
    box-shadow: inset 0 0 6px #6464644d;
    background-color: #555;
  }
}

.page-container-dark {
  background-color: #353535;

  &::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #7a7a7a4d;
  }

  &::-webkit-scrollbar, *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #7a7a7a4d;
  }

  &::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #777;
    box-shadow: inset 0 0 6px #777;
    background-color: #777;
  }
}

.scroll-bar-dark {
  &::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #7a7a7a4d;
  }

  &::-webkit-scrollbar, *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #7a7a7a4d;
  }

  &::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px #777;
    box-shadow: inset 0 0 6px #777;
    background-color: #777;
  }
}

.login-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
}

.data-table {
  .data-table-header {
    position: absolute;
    width: 100%;
    height: 56px;
    background-color: #0000004d;
  }

  .data-table-row:nth-child(odd) {
    background-color: #0000001a;
  }

  .data-table-footer {
    background-color: #0000004d;
  }
}

@media screen and (max-height: 560px) {
  .login-footer {
    position: static;
    border-top: 1px solid #202020;
  }
}